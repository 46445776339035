import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Layout from '../../components/Layout'
import Footer from '../../components/footer'
import Header from '../../components/header'
import facebooklogo from '../../assets/images/icon/facebook.svg'
import linkedinlogo from '../../assets/images/icon/linkedin.svg'
import ddlogo from '../../assets/images/icon/dd.svg'
import knsiconwhite from '../../assets/images/icon/kns-icon-white.svg'
import smsicon from '../../assets/images/icon/sms-icon.svg'
import mailicon from '../../assets/images/icon/mail-black.svg'
import pushicon from '../../assets/images/icon/notification.svg'
import ImageGallery from 'react-image-gallery'
import $ from 'jquery'
import LocalizedLink from '../../LocalizedLink'

class ProductCrm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedProject: null,
      images: [],
    }
  }

  setSelectedProject(name, array) {
    this.setState({ selectedProject: name, images: array })
  }

  componentDidMount = () => {
    let $ = window.$

    $('.header').css('position', 'absolute')
    $('.menu ul li a span').css('color', '#fff !important')
    $('.menu ul li a').css('color', '#fff !important')
    $(document).ready(function() {
      $('.mb-nav-btn').click(function() {
        $('.mobile-menu').fadeIn(200)
      })
      $('.close').click(function() {
        $('.mobile-menu').fadeOut(200)
      })

      $('.mobile-menu ul li a').click(function() {
        $('.mobile-menu').fadeOut(200)
      })
    })
  }

  render() {
    return (
      <Layout locale={this.props.pageContext.locale}>
        <body className="dt-page">
          <header className="header">
            <div className="row">
              <div className="col-3 logo">
                <a href="/">
                  <img
                    src={require('../../assets/images/logo/kns.svg')}
                    alt="KNS"
                    title="KNS"
                  ></img>
                </a>
              </div>
              <div className="col-9">
                {/*<div href="#" className="language">*/}

                {/*{this.props.pageContext.locale === "en" ? this.state.en : this.state.tr}*/}

                {/*</div>*/}
                <a href="#" id="nav-toggle" class="mb-nav-btn">
                  <span></span>
                </a>
                <nav className="menu">
                  <ul>
                    <li>
                      <LocalizedLink to="/aboutPage">
                        <FormattedMessage id="about" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platformsPage">
                        <FormattedMessage id="platforms" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/supportPage">
                        <FormattedMessage id="supports" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/clientsPage">
                        <FormattedMessage id="clients" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platforms/venuex">
                        <FormattedMessage id="Venuex" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/careerPage">
                        <FormattedMessage id="Career" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/contactPage">
                        <FormattedMessage id="Contact" />
                      </LocalizedLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </header>

          <div className="mobile-menu">
            <div className="close">
              <img src={require('../../assets/images/icon/close-nav.svg')} />
            </div>

            <nav className="mb-nav">
              <ul>
                <li>
                  <LocalizedLink to="/aboutPage">
                    <FormattedMessage id="about" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platformsPage">
                    <FormattedMessage id="platforms" />
                  </LocalizedLink>
                </li>
                <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                <li>
                  <LocalizedLink to="/supportPage">
                    <FormattedMessage id="supports" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/clientsPage">
                    <FormattedMessage id="clients" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platforms/venuex">
                    <FormattedMessage id="Venuex" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/careerPage">
                    <FormattedMessage id="Career" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/contactPage">
                    <FormattedMessage id="Contact" />
                  </LocalizedLink>
                </li>
              </ul>
            </nav>
          </div>

          <section className="solution-detail crm">
            <div className="dark-filter"></div>
            <section className="page">
              <div className="container">
                <div className="col-12">
                  <figure className="kns-grey-icon">
                    <img src={knsiconwhite}></img>
                  </figure>
                  <div className="page-prp">
                    {this.props.pageContext.locale == 'tr' && (
                      <>
                        <h1>Customer Data and Marketing Platform</h1>
                        <p>
                        Collect and Unify Data From Each Online and Physical Customer Touchpoint 
                        </p>
                      </>
                    )}
                    {this.props.pageContext.locale == 'en' && (
                      <>
                        <h1>Customer Data and Marketing Platform</h1>
                        <p>
                        Collect and Unify Data From Each Online and Physical Customer Touchpoint 
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </section>
            {/*<div className="tags"><span className="software">Software</span><span className="hardware">Hardware</span></div>*/}
          </section>
          <div className="container">
            <article className="dt-content dtpage">
              <div className="row content-prp">
                {/*<div className="col-6 text-right"><img src={ddlogo}></img></div>*/}
                <div className="text-left">
                  {/*<ul>*/}
                  {/*{this.props.pageContext.locale == "tr" &&*/}
                  {/*<>*/}
                  {/*<li>Customer Relationship Management Platform reaches the visitors through every*/}
                  {/*possible channel: E-mail, SMS and Push Notifications.*/}
                  {/*</li>*/}
                  {/*<li>It stores all the user data (most used channels, areas of interest, expenses*/}
                  {/*etc.) together on the same platform.*/}
                  {/*</li>*/}
                  {/*<li>The malls have the opportunity to understand customer behavior with the help of*/}
                  {/*purchase history, favorite choices and locations they’ve been to.*/}
                  {/*</li>*/}
                  {/*<li>Customer Relationship Management Platform helps to create a single customer*/}
                  {/*profile by integrating online and offline data.*/}
                  {/*</li>*/}
                  {/*<li>It helps creating customized campaigns that have specific target audiences and*/}
                  {/*strategies in integrated channels.*/}
                  {/*</li>*/}
                  {/*<li>It offers the mall authority valuable insights into the customer behavior and*/}
                  {/*guides the mall management through future marketing strategies.*/}
                  {/*</li>*/}
                  {/*</>}*/}

                  {/*{this.props.pageContext.locale == "en" &&*/}
                  {/*<>*/}
                  {/*<li>Customer Relationship Management Platform reaches the visitors through every*/}
                  {/*possible channel: E-mail, SMS and Push Notifications.*/}
                  {/*</li>*/}
                  {/*<li>It stores all the user data (most used channels, areas of interest, expenses*/}
                  {/*etc.) together on the same platform.*/}
                  {/*</li>*/}
                  {/*<li>The malls have the opportunity to understand customer behavior with the help of*/}
                  {/*purchase history, favorite choices and locations they’ve been to.*/}
                  {/*</li>*/}
                  {/*<li>Customer Relationship Management Platform helps to create a single customer*/}
                  {/*profile by integrating online and offline data.*/}
                  {/*</li>*/}
                  {/*<li>It helps creating customized campaigns that have specific target audiences and*/}
                  {/*strategies in integrated channels.*/}
                  {/*</li>*/}
                  {/*<li>It offers the mall authority valuable insights into the customer behavior and*/}
                  {/*guides the mall management through future marketing strategies.*/}
                  {/*</li>*/}
                  {/*</>}*/}

                  {/*</ul>*/}

                  {this.props.pageContext.locale == 'en' && (
                    <>
                      <aside>
                        <h2>Customer Engagement</h2>
                        <p>
                          Complete solutions that will help you drive better
                          outcomes for your customers and business, throughout
                          the customer journey. Easy to use without coding
                          skills.
                        </p>
                      </aside>

                      <aside>
                        <h2>Unified Customer Collection</h2>
                        <p>
                          Single platform to collect customer data into a single
                          data collection. In addition to any offline or
                          third-party data, we match all touchpoints across all
                          devices to individual profiles. This creates a true
                          360° unified profile view that provides actionable
                          insights for each customer.
                        </p>
                      </aside>

                      <aside>
                        <h2>Understand Customer Behavior</h2>
                        <p>
                          Divide customers into groups based on common
                          characteristics, interests, behaviors and performed
                          actions so you can market to each group effectively
                          and appropriately.
                        </p>
                      </aside>

                      <aside>
                        <h2>Targeted Marketing</h2>
                        <p>
                          Create targeted marketing messages that will resonate
                          with specific groups of customers, but not with others
                          who will receive messages tailored to their needs and
                          interests. Instead of trying to reach an entire
                          market, use target marketing to put your energy into
                          connecting with a specific, defined group.
                        </p>
                      </aside>

                      <aside>
                        <h2>Reports</h2>
                        <p>
                          Organized data that is hold in a single collection
                          makes it possible to extract reports and such reports
                          can guide stakeholders about decision-making and
                          problem-solving.
                        </p>
                      </aside>

                      <aside className="mt-70 text-center">
                        <h1>COMMUNICATION CHANNELS</h1>
                        <p>
                          Customer Data Platform reaches the
                          visitors through every possible channel.
                        </p>
                      </aside>

                      <div className="bd-example bd-example-tabs crmtab">
                        <nav>
                          <div
                            className="nav nav-tabs"
                            id="nav-tab"
                            role="tablist"
                          >
                            <a
                              className="nav-item nav-link active show"
                              id="nav-home-tab"
                              data-toggle="tab"
                              href="#nav-home"
                              role="tab"
                              aria-controls="nav-home"
                              aria-selected="true"
                            >
                              <img src={smsicon} className="crmicon"></img>SMS
                            </a>
                            <a
                              className="nav-item nav-link"
                              id="nav-profile-tab"
                              data-toggle="tab"
                              href="#nav-profile"
                              role="tab"
                              aria-controls="nav-profile"
                              aria-selected="false"
                            >
                              <img
                                src={mailicon}
                                className="mailicon"
                                width={28}
                              ></img>
                              EMAIL
                            </a>
                            <a
                              className="nav-item nav-link"
                              id="nav-contact-tab"
                              data-toggle="tab"
                              href="#nav-contact"
                              role="tab"
                              aria-controls="nav-contact"
                              aria-selected="false"
                            >
                              <img
                                src={pushicon}
                                className="pushicon"
                                width={24}
                              ></img>
                              PUSH NOTIFICATION
                            </a>
                          </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                          <div
                            className="tab-pane fade active show"
                            id="nav-home"
                            role="tabpanel"
                            aria-labelledby="nav-home-tab"
                          >
                            <p>
                              SMS helps you create an easy, fast and relevant
                              communications with your target visitors. It’s the
                              easiest way to reach your target instantly and
                              directly. The high open rate of SMS gives you a
                              limitless potential for creating the best
                              experience for all demographics.
                            </p>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="nav-profile"
                            role="tabpanel"
                            aria-labelledby="nav-profile-tab"
                          >
                            <p>
                              E-mail is a flexible yet affordable option for
                              marketing communications. You can easily track
                              every analytics such as who opened, who clicked,
                              who read, who didn’t read your e-mails. Tracking
                              all your efforts will let you understand the
                              insights and guide you giving your marketing
                              decisions.
                            </p>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="nav-contact"
                            role="tabpanel"
                            aria-labelledby="nav-contact-tab"
                          >
                            <p>
                              Push Notifications are instant and eye catching.
                              Users respond fastly when it appears on their
                              screen. You can send notification messages to
                              drive user re-engagement and retention.
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* <a className="fancybox" href="https://source.unsplash.com/juHayWuaaoQ/1500x1000" data-fancybox="images" data-caption="Backpackers following a dirt trail">
                         <img src="https://source.unsplash.com/juHayWuaaoQ/240x160" />
                         </a>

                         <a className="fancybox" href="https://source.unsplash.com/eWFdaPRFjwE/1500x1000" data-fancybox="images" data-caption="Mallorca, Llubí, Spain">
                         <img src="https://source.unsplash.com/eWFdaPRFjwE/240x160" />
                         </a>

                         <a className="fancybox" href="https://source.unsplash.com/c1JxO-uAZd0/1500x1000" data-fancybox="images" data-caption="Danish summer">
                         <img src="https://source.unsplash.com/c1JxO-uAZd0/240x160" />
                         </a>

                         <a className="fancybox" href="https://source.unsplash.com/eXHeq48Z-Q4/1500x1000" data-fancybox="images" data-caption="Sunrise above a sandy beach">
                         <img src="https://source.unsplash.com/eXHeq48Z-Q4/240x160" />
                         </a>

                         <a className="fancybox" href="https://source.unsplash.com/RFgO9B_OR4g/1500x1000" data-fancybox="images" data-caption="Woman on a slope by the shore">
                         <img src="https://source.unsplash.com/RFgO9B_OR4g/240x160" />
                         </a>

                         <a className="fancybox" href="https://source.unsplash.com/7bwQXzbF6KE/1500x1000" data-fancybox="images" data-caption="Mountain hiking sunset">
                         <img src="https://source.unsplash.com/7bwQXzbF6KE/240x160" />
                         </a>

                         <a className="fancybox" href="https://source.unsplash.com/NhU0nUR7920/1500x1000" data-fancybox="images" data-caption="Sunset Picnic">
                         <img src="https://source.unsplash.com/NhU0nUR7920/240x160" />
                         </a>

                         <a className="fancybox" href="https://source.unsplash.com/B2LYYV9-y0s/1500x1000" data-fancybox="images" data-caption="On them Indiana Nights">
                         <img src="https://source.unsplash.com/B2LYYV9-y0s/240x160" />
                         </a> */}
            </article>
          </div>
          <article className="home-social social-bar">
            <h2>
              <FormattedMessage id="follow" />
            </h2>
            <figure className="blue-line"></figure>
            <a
              href="https://www.facebook.com/knsbilisim/"
              className="fb"
              target="_blank"
            >
              <img src={facebooklogo}></img>
            </a>
            <a
              href="https://www.linkedin.com/company/kns-information-technologies/"
              className="linkedin"
              target="_blank"
            >
              <img src={linkedinlogo}></img>
            </a>
          </article>
          <Footer />
        </body>
      </Layout>
    )
  }
}
ProductCrm.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default ProductCrm
